<template>
  <div
      :class="{
        'check-box': type === 'box',
        'check-box-pill': type === 'pill',
        'check-box-switch': type === 'switch',
        'inline': inline,
        'show-text':text !== ''
      }"
      :style="{
        '--text-width': textWidth,
        '--font-size': fSize,
        '--font-color': fColor,
        '--padding-left-box':paddingLeftBox
      }"
      class="check"
  >
    <input
        ref="checkbox"
        :checked="value"
        :disabled="disabled"
        class="input"
        type="checkbox"
        @change="$emit('change')"
        @click="$emit('click', $event)"
        @input="updateCheck"
    >

    <div v-if="type === 'box' || type === 'switch'" class="checkbox">
      <span v-if="type === 'switch'" class="on">ON</span>
      <span v-if="type === 'switch'" class="off">OFF</span>
    </div>

    <div v-if="text !== ''" class="checkbox-text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'CheckBox',

  props: {
    change: { type: Function },
    check: { type: Function },
    checked: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    fColor: { type: String, default: '#495057' },
    fSize: { type: String, default: '.8rem' },
    inline: { type: Boolean, default: false },
    name: { type: String, default: '' },
    paddingLeftBox: { type: String, default: '2rem' },
    text: { type: String, default: '' },
    textWidth: { type: String, default: '100%' },
    type: { type: String, enum: ['box', 'pill', 'switch'], default: 'box' },
    uncheck: { type: Function },
    value: { type: Boolean, default: false },
  },

  methods: {
    updateCheck() {
      this.$emit('input', this.$refs.checkbox.checked);
      if ( this.change ) this.change(this.$refs.checkbox.checked);
      if ( this.$refs.checkbox.checked && this.check ) this.check();
      if ( !this.$refs.checkbox.checked && this.uncheck ) this.uncheck();
    }
  }
}
</script>

<style scoped lang="scss">
  .check {
    $text-width: '50px';
    line-height: 23px;
    min-width: 23px;
    min-height: 20px;
    font-size: .8rem;

    &.show-text {
      display: grid;
      grid-template-columns: auto auto;
      align-items: flex-start;

    }

    &-box,
    &-box-pill,
    &-box-switch {
      position: relative;

      .checkbox-text {
        font-size: var(--font-size);
        color: var(--font-color);
        min-height: 23px;
        white-space: nowrap;
        margin-left: .5rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-box {
      width: fit-content;
      padding-left: var(--padding-left-box);

      .input,
      .checkbox {
        cursor: pointer;
        width: 20px;
        height: 20px;
        color: white;
        position: absolute;

        &:disabled {
          cursor: default;
        }
      }

      .input {
        z-index: 99999999;
        opacity: 0;

        &:checked ~ .checkbox {
          &:before {
            content: '';
            background: color(bg-primary);
            border-radius: 2px;
            position: absolute;
            width: 10px;
            height: 10px;
            top: 50%;
            left: 50%;
            margin-top: -5px;
            margin-left: -5px;
          }
        }

        &:disabled ~ .checkbox {
          border-color: color(bg-gray2);

          &:before {
            content: '';
            background: color(bg-gray2);
          }
        }
      }

      .checkbox {
        border-radius: .3125rem;
        border: 1.5px solid color(bg-primary);
        opacity: 1;
      }
    }

    &-box-pill {
      input {
        opacity: 0;
        position: absolute;
        z-index: 99999999;

        &:checked ~ .checkbox-text {
          background: color(bg-primary);
          border: 1.5px solid color(bg-primary);
          color: #fff;
        }
      }

    }

    &-box-switch {
      .input {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 999;
        cursor: pointer;
        opacity: 0;
        left: 0;

        &:disabled {
          cursor: default;
        }
      }

      .input:checked + .checkbox {
        background: color(bg-primary);

        &:before {
          background: color(bg-white);
          -webkit-transform: translateX(29px);
          -ms-transform: translateX(29px);
          transform: translateX(29px);
        }

        .off {
          opacity: 0;
        }

        .on {
          opacity: 1;
        }
      }

      .checkbox {
        background: #d4d4de;
        border-radius: .7rem;
        width: 3.2rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100%;
        position: relative;

        &:before {
          content: "";
          background: color(bg-white);
          box-shadow: 0 1px 5px rgba(color(bg-black2), 1);
          border-radius: 50%;
          top: 50%;
          height: 15px;
          margin-top: -7.5px;
          width: 15px;
          left: 4px;
          position: absolute;
          transition: ease-in-out .2s;
        }

        .on,
        .off {
          font-size: .6rem;
          font-weight: $font-bold;
          text-align: center;
        }

        .off {
          text-align: left;
          color: color(bg-black3);
        }

        .on {
          opacity: 0;
          color: color(bg-white);
        }
      }
    }
  }
</style>